import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function EditResource({ resource, setFetch, closeModal, fetchResources }) {
  const [resourceName, setResourceName] = useState(resource.resource_name);
  const [role, setRole] = useState(resource.role);
  const [startDate, setStartDate] = useState(resource.start_date);
  const [endDate, setEndDate] = useState(resource.end_date);
  const [comment, setComment] = useState(resource.comment);
  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedResource = {
      resource_name: resourceName,
      role,
      start_date: startDate,
      end_date: endDate,
      comment,
    };

    try {
      await axios.put(
        `https://cspapi.jainilpatel.tech/api/v1/projects/${id}/resources/${resource.id}`,
        updatedResource
      );
      toast.success("Resource updated successfully.");
      setFetch((prev) => !prev);
      // Fetch the resources again to update the list
      await fetchResources();
      closeModal();
    } catch (error) {
      console.error("Error updating resource:", error);
      toast.error("An error occurred while updating the resource.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-200 p-8 rounded-md shadow-lg">
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg> */}
        </button>
        <h2 className="text-lg font-semibold mb-4">Edit Resource</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label>Resource Name:</label>
            <input
              type="text"
              required
              value={resourceName}
              onChange={(e) => setResourceName(e.target.value)}
              className="border rounded-md px-2 py-1 ml-2"
            />
          </div>
          <div className="mb-4">
            <label>Role:</label>
            <input
              type="text"
              required
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="border rounded-md px-2 py-1 ml-2"
            />
          </div>
          <div className="mb-4">
            <label>Start Date:</label>
            <input
              type="date"
              required
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded-md px-2 py-1 ml-2"
            />
          </div>
          <div className="mb-4">
            <label>End Date:</label>
            <input
              type="date"
              required
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded-md px-2 py-1 ml-2"
            />
          </div>
          <div className="mb-4">
            <label>Comment:</label>
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="border rounded-md px-2 py-1 ml-2"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 mr-2"
            >
              Save
            </button>
            <button
              onClick={closeModal}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditResource;
